<template>
  <span>{{ compLocation?.fullName || t('where') }}</span>
</template>

<script setup lang="ts">
const { t } = useI18n()

const props = defineProps<{
  location?: { fullName: string }
}>()

const { $search } = useNuxtApp()
const compLocation = computed(() => {
  return props.location ?? $search.parameters.location
})
</script>

<i18n lang="json">
{
  "en": {
    "where": "Where?"
  },
  "fr": {
    "where": "Où?"
  }
}
</i18n>
