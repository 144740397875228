<template>
  <span>
    {{ isChanged ? t('guests', guestsCount) : t('defaultGuests') }}
    <template v-if="compPetFriendly">&bull; {{ t('pet') }}</template>
  </span>
</template>

<script>
export default {
  props: {
    guests: {
      type: Object,
      default: null,
    },

    petFriendly: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n({
      useScope: 'local',
    })

    return { t }
  },

  computed: {
    compGuests() {
      return this.guests ?? this.$search.parameters.guests
    },

    compPetFriendly() {
      return this.petFriendly ?? this.$search.parameters.filters.petFriendly
    },

    guestsCount() {
      return (this.compGuests?.adults ?? 0) + (this.compGuests?.children ?? 0)
    },

    isChanged() {
      return Boolean(this.compGuests?.adults || this.compGuests?.children || this.compPetFriendly !== false)
    },
  },
}
</script>

<i18n lang="json">
{
  "en": {
    "pet": "Pet",
    "guests": "0 guests | {n} guest | {n} guests",
    "defaultGuests": "Who's going?"
  },
  "fr": {
    "pet": "Animaux",
    "guests": "0 invité | {n} invité | {n} invités",
    "defaultGuests": "Qui vous accompagne?"
  }
}
</i18n>
