<template>
  <span>{{ label }}</span>
</template>

<script>
export default {
  props: {
    dates: {
      type: Object,
      default: null,
    },
  },

  setup() {
    const { t } = useI18n({
      useScope: 'local',
    })

    return { t }
  },

  computed: {
    compDates() {
      return this.dates ?? this.$search.parameters.dates.dates
    },

    isDatesChanged() {
      return Boolean(this.compDates?.start && this.compDates?.end)
    },

    label() {
      if (this.isDatesChanged) {
        const start = this.$moment(this.compDates?.start)
        const end = this.$moment(this.compDates?.end)
        const sameMonth = start.month() === end.month()

        const startDateFormat = this.t(`dates.range.start.${sameMonth ? 'short' : 'long'}`)
        const endDateFormat = this.t(`dates.range.end.${sameMonth ? 'short' : 'long'}`)

        return `${start.format(startDateFormat)} - ${end.format(endDateFormat)}`
      }

      return this.t('when')
    },
  },
}
</script>

<style lang="scss" scoped>
.truncate {
  max-width: 16ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-flex;
}
</style>

<i18n src="~/locales/common/search/form/dates.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "when": "When?"
  },
  "fr": {
    "when": "Quand?"
  }
}
</i18n>
